import React from "react";
import { graphql } from "gatsby";
import HeroContainer from "../components/HeroContainer";
import TaggedTextWithDescription from "../components/TaggedTextWithDescription";
import ServiceBlock from "../components/ServiceBlock";
import SEO from "../components/SEO";
import Button from "../components/Button";
import SkillCard from "../components/SkillCard";
import { AnimatedText, AnimatedRandomX } from "../components/Animations";
import Slider from 'react-infinite-logo-slider'


const RandomBlockX = (props) => (
  <AnimatedRandomX {...props} initialPose="exit" pose="enter" />
);

export const pageQuery = graphql`
  query ServicesQuery {
    prismicSeo(data: { name: { eq: "Mosano | Services" } }) {
      data {
        name
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          url
        }
      }
    }
    allPrismicService(sort: { fields: data___index }) {
      nodes {
        data {
          architecture {
            item
          }
          business_model {
            item
          }
          description
          design {
            item
          }
          development {
            item
          }
          title
          index
        }
        uid
      }
    }
    allPrismicTech(sort: { order: ASC, fields: data___sort_order }) {
      nodes {
        data {
          description
          logo {
            localFile {
              publicURL
            }
            alt
          }
          title
        }
      }
    }
    allPrismicSkillset(sort: { order: ASC, fields: data___order_field }) {
      nodes {
        uid
        data {
          description
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
    prismicCookieConsent(uid: { eq: "cookie_consent" }) {
      data {
        setting_button_label
        accept_button_label
        decline_button_label
        close_button_label
        close_icon_alt
        title {
          text_1
          text_2
          text_3
        }
        cookies_categories {
          name
          details {
            text
          }
          policy_link {
            url
          }
          policy_link_text
          expand_button_label
          status
        }
      }
    }
  }
`;

const Services = (props: any) => {
  const allServices = props.data.allPrismicService.nodes || [];
  const allSkills = props.data.allPrismicSkillset.nodes || [];
  const allTech = props.data.allPrismicTech.nodes || [];

  return (
    <>
      <SEO {...props.data.prismicSeo.data} />
      <div className="hero-services">
        <div className="div-block-121" />
        <HeroContainer
          heroType="services"
          titleClass="full white margin-top no-margin-mobile"
          title="Your ride or die Software Studio partner"
          paragraphClass="p1 yellow max"
          paragraph={
            <AnimatedText duration={3000}>
              We’re problem solvers looking for the hardest challenges, from Startups to Enterprise-grade.
            </AnimatedText>
          }
        />
      </div>
      <RandomBlockX className="div-block-23" />
      <div className="services">
        <RandomBlockX className="div-block-90" />
        <RandomBlockX className="div-block-91" />
        <TaggedTextWithDescription
          containerType="services services-v2"
          subtitle="what we do"
          title="We help companies leverage their digital products"
          body="Apart from striving to innovate and execute work intelligently, we are
            straightforward and accountable with our partners and clients. Every
            project we take on is custom made and demands a requirement assessment
            and process adjustment."
          bodyClass="p2"
        />
      </div>
      <div className="slider-component">
        <Slider
              width="150px"
              duration={40}
              pauseOnHover={true}
              blurBorders={true}
              blurBorderColor={'#fff'}
          >
              {allTech.map((tech, index) => {
                console.log(tech.data.logo); // ✅ This logs correctly before returning JSX
                
                return (
                    <Slider.Slide key={`tech-card#${index}`}>
                        <div className="tech-card-component">
                          <img src={tech.data.logo.localFile.publicURL} alt={tech.data.title} className='tech-slide-logo' />
                        </div>
                      
                    </Slider.Slide>
                );
            })}
          </Slider>
      </div>
      <div className="services-menu" id="services">
        {allServices.map((service, index) => (
          <ServiceBlock
            key={`services-slider-${index}`}
            title={service.data.title}
            subtitle={service.data.description}
            button
            link={service.uid}
          />
        ))}
      </div>
      <div className="decision" style={{ display: "block" }}>
        <RandomBlockX className="div-block-95" />
        <RandomBlockX className="div-block-94" />
        <RandomBlockX className="div-block-93" />
        <div className="how-we-started skillset-spacer">
          <div className="container-how-we-started">
            <TaggedTextWithDescription subtitle="Our Skill Sets" />
            <h1 className="heading center medium">
              We build complex, reliable and highly scalable digital solutions
            </h1>
            <div className="line-30" />
            <div className="line-31" />
          </div>
        </div>
        <div className="skillset-section">
          {allSkills.map((skill, index) => (
            <SkillCard
              key={`skillset-${index}`}
              title={skill.data.title}
              description={skill.data.description}
              source={skill.data.image}
              odd={index % 2}
            />
          ))}
        </div>
        <div className="container-decision">
          <h1 className="heading large-3">
            Now that you know us a little more, it&apos;s up to you to decide
            what to do next.
          </h1>
          <p className="p2">
            But don&apos;t overthink it, we can always hop on a call to help you
            learn more without being too pushy.
          </p>
        </div>
        <div className="wrapper-decision">
          <div className="div-block-13">
            <h2 className="heading-2 services__title-purple">Need evidence?</h2>
            <p className="p2">
              Still need to be convinced that we’re the right fit for you??
              It&apos;s ok, we won&apos;t take it personally. Check out our case
              studies and find the proof you need.
            </p>
            <div className="div-block-40-copy" />
            <Button
              spacer={false}
              outerClass="button-container-2 button-container-2-left fit-content"
              buttonHref="/case-studies"
              buttonHrefClass="button w-button mosano-button-more-information"
              buttonLabel="see our case studies"
              buttonLine="button-box"
            />
          </div>
          <div className="div-block-14">
            <h2 className="heading-2 services__title-purple">
              But who are we?
            </h2>
            <p className="p2">
              You need a name? A face? You want to know what pushes us out of
              bed every Monday and what it&apos;s like in our workspace?
            </p>
            <div className="div-block-40-copy" />
            <Button
              spacer={false}
              outerClass="button-container-2 button-container-2-left fit-content"
              buttonHref="/team"
              buttonHrefClass="button w-button mosano-button-more-information"
              buttonLabel="see our team"
              buttonLine="button-box"
            />
          </div>
        </div>
      </div>
    </>
  );
};

Services.propTypes = {};

export default Services;
